export const contactInfo = [
  {
    id: "c1",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png",
    text: "Send me an email at robwang.us@gmail.com",
    link: "https://mail.google.com/mail/u/0/#inbox",
  },
  {
    id: "c2",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/800px-LinkedIn_logo_initials.png",
    text: "Check out my Linkedin",
    link: "https://www.linkedin.com/in/rwang523",
  },
  {
    id: "c3",
    image: "https://cdn-icons-png.flaticon.com/512/25/25231.png",
    text: "Come see my code on GitHub",
    link: "https://github.com/RWang-Dev",
  },
  {
    id: "c4",
    image:
      "https://www.creativefabrica.com/wp-content/uploads/2021/06/13/CV-or-Resume-Icon-Graphics-13336606-1-580x376.jpg",
    text: "Download my resume here",
    link: "Robert_Wang_Resume.pdf",
  },
];
